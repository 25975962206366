import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'naris-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatChipListbox, MatChipOption, IconComponent, TranslateModule]
})
export class ChipComponent {

  /**
   * The event fired when clicking on the 'x' on a chip
   */
  @Output()
  public readonly deleted = new EventEmitter<string>();

  /**
   * A string array with the values of the shown chips
   */
  @Input()
  public labels: string[];

  @Input()
  public showDelete = true;

  @Input()
  public disabled = false;

  /**
   * The function that fires the actual event
   * @param event The string value of the label of the clicked 'x'
   */
  public delete(event: string) {
    this.deleted.emit(event);
  }
}
