
<mat-chip-listbox aria-label="filter-chips" [disabled]="disabled">
  @for (label of labels; track label) {
    <mat-chip-option [removable]="disabled">
      {{label}}
      @if (!disabled) {
        <naris-icon (click)="delete(label)">{{'cancel' | translate}}</naris-icon>
      }
    </mat-chip-option>
  }
</mat-chip-listbox>

